import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './navbar.styles.css';
import { FiChevronDown } from 'react-icons/fi';
import logo from '../../assets/main-logo.png';

const NavBar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
  };

  return (
    <div className='nav-container'>
      <div className='navbar'>
        <div className='navbar-start'>
          <Link to='/' onClick={() => console.log('Logo clicked')}>
            <img src={logo} alt='company logo' id='main-logo' />
          </Link>
        </div>
        <div className='navbar-center'>
          <ul className='navbar-center-list'>
            <li>
              <NavLink to='/' className='nav-item'>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to='/about' className='nav-item'>
                About
              </NavLink>
            </li>

            <li
              className='nav-item nav-dropdown'
              onMouseEnter={toggleDropdown}
              onMouseLeave={toggleDropdown}
            >
              Test Menu <FiChevronDown className='arrow-icon ml-2' />
              {dropdownOpen && (
                <ul className='dropdown-menu'>
                  <li className='menu-item'>
                    <NavLink to='/testinfo/1' className='nav-link' onClick={closeDropdown}>
                      UTI PCR Testing
                    </NavLink>
                  </li>
                  <li className='menu-item'>
                    <NavLink to='/testinfo/2' className='nav-link' onClick={closeDropdown}>
                      Respiratory PCR Testing
                    </NavLink>
                  </li>
                  <li className='menu-item'>
                    <NavLink to='/testinfo/3' className='nav-link' onClick={closeDropdown}>
                      Nail Fungus PCR Testing
                    </NavLink>
                  </li>
                  <li className='menu-item'>
                    <NavLink to='/testinfo/4' className='nav-link' onClick={closeDropdown}>
                      Wound PCR Testing
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <NavLink to='/contact' className='nav-item'>
                Contact
              </NavLink>
            </li>
            <li>
              <NavLink to='/careers' className='nav-item'>
                Careers
              </NavLink>
            </li>
            <li>
              <a
                href='https://gtdx.labnexus.net'
                target='_blank'
                rel='noopener noreferrer'
                className='nav-item'
              >
                Results Portal
              </a>
            </li>
          </ul>
        </div>

        <div className='navbar-end'>
          <Link to='/contact'>
            <button className='primary-btn contact-btn'>Contact Us</button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
