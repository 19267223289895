import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { FiX, FiMenu, FiChevronDown } from 'react-icons/fi';
import './navbar.styles.css';
import logo from '../../assets/main-logo.png';

const MobileNav = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const location = useLocation();

  // Toggle the menu when the menu icon is clicked
  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  // Toggle the dropdown when the dropdown link is clicked
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Handle logo click
  const handleLogoClick = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    setShowMenu(false); // Close the menu
  };

  return (
    <div className='mobileNav'>
      <div className='mobile-nav-start'>
        <Link to='/' onClick={handleLogoClick}>
          <img src={logo} alt='company logo' id='mobile-main-logo' />
        </Link>
      </div>

      <div className='menu-toggle' onClick={toggleMenu}>
        {showMenu ? (
          <FiX size={25} id='close-menu-icon' />
        ) : (
          <FiMenu size={25} id='menu-icon' />
        )}
      </div>
      {showMenu && (
        <div className='menu'>
          <ul className='menu-list'>
            <li>
              <NavLink
                to='/'
                className='menuLink'
                onClick={() => setShowMenu(!showMenu)}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/about'
                className='menuLink'
                onClick={() => setShowMenu(!showMenu)}
              >
                About
              </NavLink>
            </li>
            <li>
              <div className='mobile-dropdown-link' onClick={toggleDropdown}>
                Test Menu <FiChevronDown className='mobile-arrow-icon' />
              </div>
              {dropdownOpen && (
                <ul className='mobile-menu-list'>
                  <li className='menu-list-item'>
                    <NavLink
                      to='/testinfo/1'
                      className='dropMenuLink'
                      onClick={() => setShowMenu(!showMenu)}
                    >
                      UTI PCR Test
                    </NavLink>
                  </li>
                  <li className='menu-list-item'>
                    <NavLink
                      to='/testinfo/2'
                      className='dropMenuLink'
                      onClick={() => setShowMenu(!showMenu)}
                    >
                      Respiratory PCR Test
                    </NavLink>
                  </li>
                  <li className='menu-list-item'>
                    <NavLink
                      to='/testinfo/3'
                      className='dropMenuLink'
                      onClick={() => setShowMenu(!showMenu)}
                    >
                      Nail Fungus PCR Test
                    </NavLink>
                  </li>
                  <li className='menu-list-item'>
                    <NavLink
                      to='/testinfo/4'
                      className='dropMenuLink'
                      onClick={() => setShowMenu(!showMenu)}
                    >
                      Wound PCR Test
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>

            <li>
              <NavLink
                to='/contact'
                className='menuLink'
                onClick={() => setShowMenu(!showMenu)}
              >
                Contact
              </NavLink>
            </li>
            <li>
              <NavLink
                to='/careers'
                className='menuLink'
                onClick={() => setShowMenu(!showMenu)}
              >
                Careers
              </NavLink>
            </li>

            <li>
              <a
                href='https://gtdx.labnexus.net'
                target='_blank'
                rel='noopener noreferrer'
                className='menuLink'
                onClick={() => setShowMenu(!showMenu)}
              >
                Results Portal
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MobileNav;
