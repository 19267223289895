import React from 'react';
import GeneralContact from '../components/contactForms/GeneralContact';
import '../pageStyles/contact.styles.css';


const Contact = () => {
  return (
    <div className='contact-wrapper'>
      <GeneralContact />
    </div>
  );
};

export default Contact;
