const testData = [
  {
    id: 1,
    path: '/testinfo/a',
    title: 'UTI Pathogen Testing',
    subtitle: 'Urine Testing for UTIs',
    description:
      'This advanced Molecular PCR UTI Test identifies bacteria in urine samples and determines which bacteria are causing the infection. It also reveals the antibiotic resistance profile of the bacteria, aiding in effective treatment planning.',
    benefits:
      "With swift and precise results, this test facilitates targeted antibiotic treatment, improving patient outcomes. It helps reduce the use of broad-spectrum antibiotics and combats antibiotic resistance. It's particularly beneficial for patients with persistent or complex UTIs, or those unresponsive to standard treatments.",
    reason:
      'Ideal for patients exhibiting UTI symptoms like frequent urination, painful urination, cloudy or foul-smelling urine, and lower abdominal or pelvic pain. Especially useful for recurrent infections or non-responsive cases, this test aids healthcare providers in confirming UTIs, guiding antibiotic use, and monitoring treatment efficacy.',
    sample:
      'This test requires a urine sample, which can be collected at home or in a healthcare setting, and sent to our lab for analysis.',
    thumbnailImg:
      'https://res.cloudinary.com/dh8uixj4v/image/upload/v1719457840/shutterstock_2209960617_1_l1shsi.jpg',
    accentImg:
      'https://res.cloudinary.com/dh8uixj4v/image/upload/v1721157335/shutterstock_2433373053_bq6xc6.jpg',
  },
  {
    id: 2,
    path: '/testinfo/b',
    title: 'Respiratory Pathogen Testing',
    subtitle: 'Respiratory testing for infections',
    description:
      'The Molecular PCR Respiratory Test identifies bacteria in respiratory samples like sputum or bronchoalveolar lavage fluid. It specifies the bacteria causing the infection and outlines the antibiotic resistance profile, aiding in tailored treatment decisions.',
    benefits:
      'Providing rapid and accurate results, this test allows for targeted antibiotic therapy and improved patient outcomes. It helps reduce the unnecessary use of broad-spectrum antibiotics and minimizes the development of antibiotic resistance. This is particularly useful for patients with respiratory infections that do not respond to standard treatment or for those at risk of antibiotic-resistant infections.',
    reason:
      'This diagnostic test is recommended for patients with symptoms of a respiratory infection, such as cough, shortness of breath, and chest pain. It is also indicated for patients with chronic respiratory conditions, such as chronic obstructive pulmonary disease (COPD) or cystic fibrosis, who are at increased risk of respiratory infections. The test may be ordered by a healthcare provider to confirm a suspected respiratory infection, guide antibiotic therapy, or monitor treatment effectiveness.',
    sample:
      'This test requires a respiratory nasal swab sample, which can be collected at home or in a healthcare setting. The sample is then sent to our laboratory for analysis.',
    thumbnailImg:
      'https://res.cloudinary.com/dh8uixj4v/image/upload/v1719457779/shutterstock_2130533783_1_jvp7br.jpg',
    accentImg:
      'https://res.cloudinary.com/dh8uixj4v/image/upload/v1719456491/shutterstock_1954074400_iujo9w.jpg',
  },
  {
    id: 3,
    path: '/testinfo/c',
    title: 'Nail Pathogen Testing',
    subtitle: 'Testing for nail fungus',
    description:
      'Molecular Nail Fungus Testing is a diagnostic test that detects the presence of fungal pathogens in nail samples. The test identifies the specific fungus causing the infection and helps guide treatment decisions.',
    benefits:
      'The test provides rapid and accurate results, allowing for targeted antifungal therapy and improved patient outcomes. It can help reduce the unnecessary use of systemic antifungal medications and minimize the development of antifungal resistance. The test is particularly useful for patients with chronic or recurrent nail infections that do not respond to standard treatment.',
    reason:
      'The test is recommended for patients with symptoms of a nail infection, such as thickened, discolored, or brittle nails. It is also indicated for patients with chronic or recurrent nail infections that do not respond to standard treatment. The test may be ordered by a healthcare provider to confirm a suspected fungal nail infection, guide antifungal therapy, or monitor treatment effectiveness.',
    sample:
      'The test requires a nail sample, which can be collected at home or in a healthcare setting. The sample is then sent to our laboratory for analysis.',
    thumbnailImg:
      'https://res.cloudinary.com/dh8uixj4v/image/upload/v1719804656/shutterstock_537969037_nhxmyt.webp',
    accentImg:
      'https://res.cloudinary.com/dh8uixj4v/image/upload/v1719805772/shutterstock_2341466789_hrnigf.webp',
  },
  {
    id: 4,
    path: '/testinfo/d',
    title: 'Wound Pathogen Testing',
    subtitle: 'Testing for wound infections',
    description:
      'Molecular PCR Wound Testing is a diagnostic test that detects the presence of bacteria in wound samples. The test identifies the specific bacteria causing the infection and helps guide treatment decisions.',
    benefits:
      'The test provides rapid and accurate results, allowing for targeted antibiotic therapy and improved wound healing. It can help reduce the unnecessary use of broad-spectrum antibiotics and minimize the development of antibiotic resistance. The test is particularly useful for patients with chronic or non-healing wounds, as well as those at risk of wound infections.',
    reason:
      'The test is recommended for patients with symptoms of a wound infection, such as redness, swelling, warmth, or pus drainage. It is also indicated for patients with chronic or non-healing wounds that do not respond to standard treatment. The test may be ordered by a healthcare provider to confirm a suspected wound infection, guide antibiotic therapy, or monitor treatment effectiveness.',
    sample:
      'The test requires a wound swab sample, which can be collected at home or in a healthcare setting. The sample is then sent to our laboratory for analysis.',
    thumbnailImg:
      'https://res.cloudinary.com/dh8uixj4v/image/upload/v1719557925/shutterstock_146119724_1_tlgg6b.webp',
      accentImg:
      'https://res.cloudinary.com/dh8uixj4v/image/upload/v1719804957/shutterstock_1975525775_mcknsw.webp',
  },
];

export default testData;
