import React from 'react';
import { Link } from 'react-router-dom';

const SimpleContact = () => {
  return (
    <div className='simple-contact-section'>
      <Link to='/contact'>
        <h2>Contact Us</h2>
      </Link>
      <h3>
        If you have any questions or would like to learn more about our
        services, please contact us:
      </h3>
      <p>Email: contactus@gtdxlabs.com</p>
      <p>Phone: (214) 954-7483</p>
      <p>Address: 1701 N. Collins Blvd. Suite 1000</p>
    </div>
  );
};

export default SimpleContact;
