import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// Pages and Components
import Navigation from './components/navigation/Navigation';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import TestInfo from './pages/TestInfo';
import Careers from './pages/Careers';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Footer from './components/footer/Footer';
import ScrollTop from './components/Scroll/ScrollTop';

function App() {
  return (
    <>
      <Router>
        <Navigation />
        <ScrollTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/testinfo/:id' element={<TestInfo />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
