import React, { useState } from 'react';
import emailJs from '@emailjs/browser';
import '../../pageStyles/careers.styles.css';

const CareerContact = () => {
  const states = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
  ];

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    city: '',
    state: '',
    position: '',
    yearsExperience: '',
    salesStyle: '',
    questionC: '',
    questionD: '',
    // resume: null,
    message: '',
  });

  const [buttonText, setButtonText] = useState('Submit');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'resume') {
      setFormData({
        ...formData,
        resume: files[0],
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonText('Sending...');
    setSuccessMessage('');
    setErrorMessage('');

    emailJs
      .sendForm('service_pr8gmfu', 'template_bc3pzau', e.target, 'SiiRe5y6d6R11L4br')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setButtonText('Sent');
        setSuccessMessage("Thank you for applying! We'll get back to you shortly.");
      })
      .catch((err) => {
        setErrorMessage('Something went wrong. Please try again.');
        console.log('FAILED...', err);
        setButtonText('Submit');
      });
  };

  return (
    <div className='career-form'>
      <form onSubmit={handleSubmit}>
        <div className='form-row'>
          <div className='form-group'>
            <label htmlFor='firstName'>First Name</label>
            <input
              type='text'
              id='firstName'
              name='firstName'
              value={formData.firstName}
              onChange={handleChange}
              autoComplete='given-name'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='lastName'>Last Name</label>
            <input
              type='text'
              id='lastName'
              name='lastName'
              value={formData.lastName}
              onChange={handleChange}
              autoComplete='family-name'
            />
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input
              type='email'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              autoComplete='email'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='phoneNumber'>Phone Number</label>
            <input
              type='tel'
              id='phoneNumber'
              name='phoneNumber'
              value={formData.phoneNumber}
              onChange={handleChange}
              autoComplete='tel-national'
            />
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group'>
            <label htmlFor='city'>City</label>
            <input
              type='text'
              id='city'
              name='city'
              value={formData.city}
              onChange={handleChange}
              autoComplete='address-level2'
            />
          </div>
          <div className='form-group'>
            <label htmlFor='state'>State</label>
            <select
              id='state'
              name='state'
              value={formData.state}
              onChange={handleChange}
              autoComplete='address-level1'
            >
              {states.map((state) => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.abbreviation}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='form-group'>
          <label htmlFor='position'>Position You Are Applying For</label>
          <input
            type='text'
            id='position'
            name='position'
            value={formData.position}
            onChange={handleChange}
            autoComplete='off'
          />
        </div>
        <div className='form-group'>
          <label htmlFor='yearsExperience'>How many years of outside sales experience do you have?</label>
          <input
            type='text'
            id='yearsExperience'
            name='yearsExperience'
            value={formData.yearsExperience}
            onChange={handleChange}
            autoComplete='off'
          />
        </div>
        <div className='form-group'>
          <label htmlFor='salesStyle'>How would you describe your sales style?</label>
          <input
            type='text'
            id='salesStyle'
            name='salesStyle'
            value={formData.salesStyle}
            onChange={handleChange}
            autoComplete='off'
          />
        </div>
        <div className='form-group'>
          <label htmlFor='questionC'>Have you ever sold to physicians or hospitals?</label>
          <input
            type='text'
            id='questionC'
            name='questionC'
            value={formData.questionC}
            onChange={handleChange}
            autoComplete='off'
          />
        </div>
        <div className='form-group'>
          <label htmlFor='questionD'>In a medical office, how do you get past the gatekeeper?</label>
          <input
            type='text'
            id='questionD'
            name='questionD'
            value={formData.questionD}
            onChange={handleChange}
            autoComplete='off'
          />
        </div>
        {/* <div className='form-group'>
          <label htmlFor='resume'>Upload Resume</label>
          <input
            type='file'
            id='resume'
            name='resume'
            onChange={handleChange}
          />
        </div> */}
        <div className='form-group'>
          <label htmlFor='message'>Message (optional)</label>
          <textarea
            id='message'
            name='message'
            value={formData.message}
            onChange={handleChange}
            autoComplete='off'
          ></textarea>
        </div>
        <button type='submit' className='btn primary-btn'>
          {buttonText}
        </button>
        {successMessage && <p className='success-message'>{successMessage}</p>}
        {errorMessage && <p className='error-message'>{errorMessage}</p>}
      </form>
    </div>
  );
};

export default CareerContact;


