import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import testData from '../../assets/TestData';
import './testGrid.styles.css';

const TestGrid = () => {
  useEffect(() => {
    const cards = document.querySelectorAll('.test-card');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (
            entry.isIntersecting &&
            !entry.target.classList.contains('animated')
          ) {
            entry.target.classList.add('in-view', 'animated');
          } else if (!entry.target.classList.contains('animated')) {
            entry.target.classList.remove('in-view');
          }
        });
      },
      { threshold: 0 }
    );

    cards.forEach((card) => {
      observer.observe(card);
    });

    return () => {
      cards.forEach((card) => {
        observer.unobserve(card);
      });
    };
  }, []);

  return (
    <div className='grid-container'>
      {testData.map((test) => (
        <Link to={`/testinfo/${test.id}`} key={test.id}>
          <div className='test-card'>
            <img
              src={test.thumbnailImg}
              alt='test-img'
              className='thumbnailImg'
            />
            <div className='card-text'>
              <h1 className='test-title'>
                {test.title}
                <br />
              </h1>
              <p className='test-subtitle'>
                With Antibiotic Resistance Markers
              </p>
            </div>
            <div className='card-actions mt-5'>
              <button className='learn-btn'>
                Learn More
                <FiChevronRight className='arrow-icon ml-2' />
              </button>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default TestGrid;
