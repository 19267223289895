import React from 'react';
import { Link } from 'react-router-dom';
// import '../../pageStyles/testInfo.styles.css';
import './infoCard.styles.css';

const InfoCard = () => {
  return (
    <>
      <div className='info-card'>
        <div className='info-card-body'>
          <p className='info-card-text'>PCR Tests are 98% Accurate</p>
          <div className='card-img-container'>
            <img
              src='https://res.cloudinary.com/dh8uixj4v/image/upload/v1719456781/shutterstock_2147227297_vd3top.png'
              alt='Description'
              className='card-img-top'
            />
          </div>
          <div className='button-container'>
            <Link to='/about'>
              <button className='mini-btn'>Learn More</button>
            </Link>
          </div>
        </div>
      </div>
      <div className='info-card'>
        <div className='info-card-body'>
          <p className='info-card-text'>Tests For Antibiotic Resistances</p>
          <div className='card-img-container'>
            <img
              src='https://res.cloudinary.com/dh8uixj4v/image/upload/v1719456802/shutterstock_2147227297_wmougi.png'
              alt='Description'
              className='card-img-top'
            />
          </div>
          <div className='button-container'>
            <Link to='/about'>
              <button className='mini-btn'>Learn More</button>
            </Link>
          </div>
        </div>
      </div>
      <div className='info-card'>
        <div className='info-card-body'>
          <p className='info-card-text'>Fast Accurate Results</p>
          <div className='card-img-container'>
            <img
              src='https://res.cloudinary.com/dh8uixj4v/image/upload/v1719456670/shutterstock_2127545831_t29apg.png'
              alt='Description'
              className='card-img-top'
            />
          </div>
          <div className='button-container'>
            <Link to='/about'>
              <button className='mini-btn'>Learn More</button>
            </Link>
          </div>
        </div>
      </div>
      <div className='info-card'>
        <div className='info-card-body'>
          <p className='info-card-text'>Dedicated Support Agent</p>
          <div className='card-img-container'>
            <img
              src='https://res.cloudinary.com/dh8uixj4v/image/upload/v1719456890/shutterstock_2127545831_vqitcr.png'
              alt='Description'
              className='card-img-top'
            />
          </div>
          <div className='button-container'>
            <Link to='/about'>
              <button className='mini-btn'>Learn More</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoCard;
