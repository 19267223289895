import React from 'react';
import { Link } from 'react-router-dom';
import '../pageStyles/about.styles.css';
import SimpleContact from '../components/contactForms/SimpleContact';
import RowCard from '../components/Cards/RowCard';
import { FiCheck } from 'react-icons/fi';

const About = () => {
  return (
    <>
      <div className='about-container'>
        <div className='about-hero'>
          <header className='main-about-header'>
            <h1>Welcome to Grand Teton</h1>
          </header>
        </div>

        <div className='about-info-content'>
          <div className='about-text'>
            <div className='about-top-grid'>
              <div className='about-grid-item'>
                <img
                  src='https://res.cloudinary.com/dh8uixj4v/image/upload/v1719456394/shutterstock_2422225063_b3vmub.png'
                  alt='about-img'
                  loading='lazy'
                  className='t-img'
                />
                <h1 className='about-subheader'>Who We Are</h1>
                <p className='about-body'>
                  At Grand Teton we are dedicated to delivering fast and
                  accurate testing. Our state-of-the-art laboratory and highly
                  skilled team are dedicated to providing precise and timely
                  results to support physicians in delivering the best care to
                  their patients. We understand the critical role that rapid and
                  reliable diagnostic testing plays in the healthcare process,
                  and we are committed to excellence in every test we perform.
                </p>
              </div>
              <div className='about-grid-item'>
                <img
                  src='https://res.cloudinary.com/dh8uixj4v/image/upload/v1719453332/shutterstock_2422225063_wcykmt.png'
                  alt='about-img'
                  loading='lazy'
                  className='t-img'
                />
                <h1 className='about-subheader'>What We Do</h1>
                <p className='about-body'>
                  Our services focus on molecular PCR testing, a highly
                  sensitive method used to detect genetic material from specific
                  organisms, such as viruses and bacteria. This type of testing
                  is crucial for diagnosing infections, monitoring treatment
                  effectiveness, and guiding patient management decisions.
                </p>
              </div>
            </div>
            <div className='pcr-info-section'>
              <RowCard />
            </div>
            <div className='about-services'>
              <h1 className='about-subheader mt-10'>
                By choosing Grand Teton, physicians can expect:
              </h1>
              <ul className='services-list'>
                <li>
                  <FiCheck className='check-icon' />
                  Fast turnaround times
                </li>
                <li>
                  <FiCheck className='check-icon' />
                  Highly accurate and reliable test results
                </li>
                <li>
                  <FiCheck className='check-icon' />
                  Comprehensive testing panels covering a wide range of
                  pathogens
                </li>
                <li>
                  <FiCheck className='check-icon' />
                  Supportive customer service and technical support
                </li>
              </ul>
            </div>
            <div className='why-section'>
              <h1 className='about-subheader'>Why Choose Us?</h1>
              <p className='about-body-b'>
                Physicians choose Grand Teton for our commitment to quality and
                speed. Our rapid turnaround times mean that patients can receive
                the care they need more quickly, potentially improving outcomes
                and reducing the spread of infectious diseases. Our focus on
                molecular PCR testing ensures that physicians have access to the
                most advanced diagnostic tools available, helping them to make
                informed decisions about patient care. Trust Grand Teton for
                reliable, fast, and accurate diagnostic testing.
              </p>
            </div>
            <div className='cert-section'>
              <div className='cert-container'>
                <img
                  src='https://res.cloudinary.com/dh8uixj4v/image/upload/v1719456477/CMSLogo_sgyejv.png'
                  alt='CMS Logo'
                  className='cert-img'
                />
                <img
                  src='https://res.cloudinary.com/dh8uixj4v/image/upload/v1719461557/Screenshot_2024-06-26_at_9.12.17_PM-Photoroom_kuflxx.png'
                  alt='CLIA Logo'
                  className='cert-img'
                />
              </div>
            </div>
          </div>

          <div className='button-section'>
            <Link to='/contact' className='btn about-btn'>
              Contact Us To Get Started
            </Link>
          </div>
        </div>
      </div>
      <div className='bottom-contact-row'>
        <SimpleContact />
      </div>
    </>
  );
};

export default About;
