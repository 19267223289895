import React from 'react';
import CareerContact from '../components/contactForms/CareerContact';
import SimpleContact from '../components/contactForms/SimpleContact';
import '../pageStyles/careers.styles.css';

const Careers = () => {
  return (
    <>
      <div className='career-container'>
        <div className='career-hero'>
          <div className='career-img'>
            <img
              src='https://i.ibb.co/h9VknFJ/shutterstock-2147227297-1.jpg'
              alt='career-img'
            />
          </div>
          <div className='career-header'>
            <h1>Careers</h1>
            <h2 className='career-subheader'>
              We are always looking for talented individuals to join our team
            </h2>
            <p>
              If you are passionate about healthcare and have a desire to make a
              difference, we would love to hear from you.
            </p>
          </div>
        </div>
        <div className='career-form-container'>
          <CareerContact />
        </div>
      </div>
      <div className='bottom-contact-row'>
        <SimpleContact />
      </div>
    </>
  );
};

export default Careers;
