// import React from 'react';
// import '../../pageStyles/about.styles.css';

// const RowCard = () => {
//   return (
//     <div>
//       <div className='pcr-detail-row'>
//         <img
//           src='https://i.ibb.co/9GZqqG0/bacteria-1.png'
//           alt='bacteria'
//           className='pcr-image'
//         />
//         <div className='pcr-text'>
//           <h2 className='pcr-row-subheader'>What is PCR Testing</h2>
//           <p className='about-body'>
//             PCR (Polymerase Chain Reaction) testing is a molecular biology
//             technique that amplifies specific DNA sequences, making it easier to
//             detect and analyze genetic material. This method involves repeated
//             cycles of heating and cooling to separate DNA strands, bind primers
//             to target sequences, and synthesize new DNA strands using a DNA
//             polymerase enzyme. The result is a substantial increase in the
//             amount of the target DNA sequence, allowing for detailed study and
//             detection even from minimal sample amounts.
//           </p>
//         </div>
//       </div>
//       <div className='pcr-detail-row'>
//         <img
//           src='https://i.ibb.co/307RKR9/medical-protection.png'
//           alt='bacteria'
//           className='pcr-image'
//         />
//         <div className='pcr-text'>
//           <h2 className='pcr-row-subheader'>Application of PCR Testing</h2>
//           <p className='about-body'>
//             In diagnostic testing, PCR is crucial for identifying the presence
//             of pathogens such as viruses and bacteria. By targeting unique
//             genetic markers of pathogens, PCR can provide rapid and specific
//             identification, which is essential for timely medical intervention.
//             Additionally, PCR can be used to detect genetic mutations associated
//             with hereditary diseases, making it a valuable tool in genetic
//             testing and personalized medicine.
//           </p>
//         </div>
//       </div>
//       <div className='pcr-detail-row'>
//         <img
//           src='https://i.ibb.co/rFnCJZ3/test-tube-2.png'
//           alt='bacteria'
//           className='pcr-image'
//         />
//         <div className='pcr-text'>
//           <h2 className='pcr-row-subheader'>Benefits of PCR Testing</h2>
//           <p className='about-body'>
//             One of the significant benefits of PCR over traditional culture
//             methods is its speed and sensitivity. While culture methods require
//             growing organisms in specific conditions, which can take days or
//             even weeks, PCR can produce results within hours. This rapid
//             turnaround is critical for early diagnosis and treatment. Moreover,
//             PCR's high sensitivity allows it to detect low levels of pathogens
//             that might not be viable in culture, providing a more comprehensive
//             diagnostic capability.
//           </p>
//         </div>
//       </div>
//       <div className='pcr-detail-row'>
//         <img
//           src='https://i.ibb.co/nmVJzY3/medicine.png'
//           alt='bacteria'
//           className='pcr-image'
//         />
//         <div className='pcr-text'>
//           <h2 className='pcr-row-subheader'>
//             Antibiotic Resistance and Accuracy of PCR
//           </h2>
//           <p className='about-body'>
//             PCR testing can also detect antibiotic resistance by identifying
//             specific genetic markers associated with resistance mechanisms. For
//             example, the presence of genes encoding for beta-lactamase enzymes
//             can indicate resistance to beta-lactam antibiotics. By detecting
//             these markers, PCR enables healthcare providers to choose the most
//             effective antibiotic treatment, thus improving patient outcomes.
//             Regarding accuracy, PCR is highly reliable due to its ability to
//             target specific DNA sequences. However, its accuracy depends on the
//             quality of the sample and the precision of the procedure,
//             emphasizing the need for skilled technicians and stringent protocols
//             to minimize the risk of contamination and false-positive results.
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default RowCard;


import React, { useEffect, useRef } from 'react';
import '../../pageStyles/about.styles.css';

const RowCard = () => {
  const rowRef = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log('In view:', entry.target);
          entry.target.classList.add('slide-up');
          observer.unobserve(entry.target); // To trigger the animation only once
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);

    rowRef.current.forEach((row) => {
      if (row) {
        observer.observe(row);
      }
    });

    return () => {
      if (rowRef.current) {
        rowRef.current.forEach((row) => {
          if (row) {
            observer.unobserve(row);
          }
        });
      }
    };
  }, []);

  return (
    <div>
      {[
        'What is PCR Testing',
        'Application of PCR Testing',
        'Benefits of PCR Testing',
        'Antibiotic Resistance and Accuracy of PCR',
      ].map((header, index) => (
        <div
          className="pcr-detail-row"
          ref={(el) => (rowRef.current[index] = el)}
          key={index}
        >
          <img
            src={`https://i.ibb.co/${
              index === 0
                ? '9GZqqG0/bacteria-1'
                : index === 1
                ? '307RKR9/medical-protection'
                : index === 2
                ? 'rFnCJZ3/test-tube-2'
                : 'nmVJzY3/medicine'
            }.png`}
            alt="bacteria"
            className="pcr-image"
          />
          <div className="pcr-text">
            <h2 className="pcr-row-subheader">{header}</h2>
            <p className="about-body">
              {index === 0 &&
                'PCR (Polymerase Chain Reaction) testing is a molecular biology technique that amplifies specific DNA sequences, making it easier to detect and analyze genetic material. This method involves repeated cycles of heating and cooling to separate DNA strands, bind primers to target sequences, and synthesize new DNA strands using a DNA polymerase enzyme. The result is a substantial increase in the amount of the target DNA sequence, allowing for detailed study and detection even from minimal sample amounts.'}
              {index === 1 &&
                'In diagnostic testing, PCR is crucial for identifying the presence of pathogens such as viruses and bacteria. By targeting unique genetic markers of pathogens, PCR can provide rapid and specific identification, which is essential for timely medical intervention. Additionally, PCR can be used to detect genetic mutations associated with hereditary diseases, making it a valuable tool in genetic testing and personalized medicine.'}
              {index === 2 &&
                'One of the significant benefits of PCR over traditional culture methods is its speed and sensitivity. While culture methods require growing organisms in specific conditions, which can take days or even weeks, PCR can produce results within hours. This rapid turnaround is critical for early diagnosis and treatment. Moreover, PCR\'s high sensitivity allows it to detect low levels of pathogens that might not be viable in culture, providing a more comprehensive diagnostic capability.'}
              {index === 3 &&
                'PCR testing can also detect antibiotic resistance by identifying specific genetic markers associated with resistance mechanisms. For example, the presence of genes encoding for beta-lactamase enzymes can indicate resistance to beta-lactam antibiotics. By detecting these markers, PCR enables healthcare providers to choose the most effective antibiotic treatment, thus improving patient outcomes. Regarding accuracy, PCR is highly reliable due to its ability to target specific DNA sequences. However, its accuracy depends on the quality of the sample and the precision of the procedure, emphasizing the need for skilled technicians and stringent protocols to minimize the risk of contamination and false-positive results.'}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RowCard;
