import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import WhiteLogo from '../../assets/wht-logo.png';
import './footer.styles.css';

const Footer = () => {
  const location = useLocation();

  const scrollToTop = () => {
    if (location.pathname === '/') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div className='footer-section'>
      <div className='footer-container'>
        <div className='footer-left'>
          <div className='footer-logo'>
            <Link to='/' onClick={scrollToTop}>
              <img src={WhiteLogo} alt='company logo' id='white-logo' />
            </Link>
          </div>
          <div className='footer-contact'>
            <h2 className='links-header'>Contact Us:</h2>
            <p className='footer-body'>
              1701 N. Collins Blvd. Suite 1000
              <br />
              Richardson, TX 75080
            </p>
            <p className='footer-body'>Phone: (214) 954-7483</p>
            <p className='footer-body'>Fax: (214) 258-5916</p>
            <p className='footer-body'>contactus@gtdxlabs.com</p>
          </div>
        </div>
        <div className='footer-center'>
          <div className='footer-links'>
            <ul className='footer-menu'>
              <h2 className='links-header'>Quick Links</h2>
              <Link to='/'>
                {' '}
                <li className='footer-item'>Home</li>
              </Link>
              <Link to='/about'>
                {' '}
                <li className='footer-item'>About</li>
              </Link>
              <Link to='/contact'>
                <li className='footer-item'>Contact</li>
              </Link>{' '}
              <li className='footer-item'>
                <a
                  href='https://gtdx.labnexus.net'
                  target='_blank'
                  rel='noopener noreferrer'
                  className='footer-item'
                >
                  Results Portal
                </a>
              </li>
              <Link to='/careers'>
                {' '}
                <li className='footer-item'>Careers</li>
              </Link>
            </ul>
          </div>
        </div>
        <div className='footer-right'>
          <div className='footer-test-menu'>
            <ul className='footer-menu'>
              <h2 className='links-header'>Test Menu</h2>
              <Link to='/testinfo/1'>
                <li className='test-item'>UTI Testing</li>
              </Link>
              <Link to='/testinfo/2'>
                <li className='test-item'>Respiratory Testing</li>
              </Link>
              <Link to='/testinfo/3'>
                <li className='test-item'>Nail Fungus Testing</li>
              </Link>
              <Link to='/testinfo/4'>
                <li className='test-item'>Wound Testing</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
      <div className='footer-bottom'>
        <p className='copy-right'>
          &copy; 2021 Grand Teton Diagnostics. All rights reserved.
        </p>
        {/* <div className='terms-links'>
          <p className='privacy-policy'>Privacy Policy</p>
          <p className='terms-of-use pl-3'>Terms of Use</p>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
