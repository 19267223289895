import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './hero.styles.css';

const HeroContent = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    if (video) {
      video.muted = true; // Ensure the video is muted
      video.setAttribute('playsinline', ''); // Ensure playsinline attribute is set
      video.setAttribute('webkit-playsinline', ''); // WebKit specific attribute
      video.play().catch((error) => {
        // Handle any errors (e.g., user interaction required)
        console.error('Error attempting to play video:', error);
      });
    }
  }, []);
  return (
    <div className='hero-container min-h-screen'>
      <video
        ref={videoRef}
        className='video-background'
        width='600'
        autoPlay
        muted
        playsInline
        loop
        preload='auto'
      >
        <source
          src='https://res.cloudinary.com/dh8uixj4v/video/upload/v1719781634/shutterstock_3433465771_luaupx.mp4'
          type='video/mp4'
        />
      </video>
      <div className='overlay'></div>
      <div className='hero-content'>
        <div className='hero-copy'>
          <h1 className='hero-header'>
            Advanced Diagnostic Testing for Fast Accurate Results
          </h1>
          <p className='hero-body'>
            Our Specialized Molecular PCR Testing Provides Rapid and Accurate
            Diagnostics.
          </p>
          <p className='hero-body'>
            By Leveraging the Latest Advancements, We Help Healthcare
            Professionals Make Informed Decisions for Optimal Patient Care.
          </p>
          <div className='btn-container'>
            <Link to='/about'>
              <button className='btn secondary-btn bg-white'>
                Learn More
              </button>
            </Link>
            <Link to='/contact'>
              <button className='btn primary-btn ml-5'>Get Started</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroContent;
