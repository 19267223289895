import React, { useState, useEffect, useRef } from 'react';
import emailJS from '@emailjs/browser';
import { FiPhone, FiMail, FiMap } from 'react-icons/fi';
import '../../pageStyles/contact.styles.css';

const GeneralContact = () => {
  const contactTextRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-up');
          }
        });
      },
      { threshold: 0.1 }
    );

    if (contactTextRef.current) {
      observer.observe(contactTextRef.current);
    }

    return () => {
      if (contactTextRef.current) {
        observer.unobserve(contactTextRef.current);
      }
    };
  }, []);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    practice: '',
    phone: '',
    email: '',
    message: '',
  });

  const [buttonText, setButtonText] = useState('Submit');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone) && !/^(.)\1{9}$/.test(phone);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!validateEmail(formData.email)) {
      newErrors.email = 'Please enter a valid email address.';
    }

    if (!validatePhone(formData.phone)) {
      newErrors.phone = 'Please enter a valid 10-digit phone number.';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submission intercepted');

    if (!validateForm()) {
      setErrorMessage('Please correct the errors in the form.');
      return;
    }

    setButtonText('Sending...');
    setSuccessMessage('');
    setErrorMessage('');

    const templateParams = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      practice_name: formData.practice,
      phone: formData.phone,
      email: formData.email,
      message: formData.message,
    };

    emailJS
      .send(
        'service_pr8gmfu',
        'template_f9wxbpg',
        templateParams,
        'SiiRe5y6d6R11L4br'
      )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        setButtonText('Sent');
        setSuccessMessage(
          "Thank you for reaching out! We'll get back to you shortly."
        );
      })
      .catch((err) => {
        setErrorMessage('Something went wrong. Please try again.');
        console.log('FAILED...', err);
        setButtonText('Submit');
      });
  };

  return (
    <div className='contact-container'>
      <div className='gen-contact-text' ref={contactTextRef}>
        <h1 className='contact-header'>Contact Us</h1>
        <p className='contact-subheader'>
          If you have any questions or would like to learn more about our
          services,
          <br />
          please contact us.
        </p>

        <div className='contact-info'>
          <div className='contact-item'>
            <FiPhone className='contact-icon' /> (214) 954-7483
          </div>
          <div className='contact-item'>
            <FiMail className='contact-icon' />
            contactus@gtdxlabs.com
          </div>
          <div className='contact-item'>
            <FiMap className='contact-icon' />
            1701 N. Collins Blvd. Suite 1000 Richardson, TX 75080
          </div>
        </div>
      </div>
      <div className='contact-form'>
        <form onSubmit={handleSubmit}>
          <div className='form-row'>
            <div className='form-group'>
              <label htmlFor='firstName'>First Name</label>
              <input
                type='text'
                id='firstName'
                name='firstName'
                value={formData.firstName}
                onChange={handleChange}
                autoComplete='given-name'
                required
              />
            </div>
            <div className='form-group'>
              <label htmlFor='lastName'>Last Name</label>
              <input
                type='text'
                id='lastName'
                name='lastName'
                value={formData.lastName}
                onChange={handleChange}
                autoComplete='family-name'
                required
              />
            </div>
          </div>
          <div className='form-group'>
            <label htmlFor='practice-name'>Practice Name</label>
            <input
              type='text'
              id='practice-name'
              name='practice'
              value={formData.practice}
              onChange={handleChange}
              autoComplete='organization-title'
              required
            />
          </div>
          <div className='form-group'>
            <label htmlFor='phone'>Phone</label>
            <input
              type='tel'
              id='phone'
              name='phone'
              value={formData.phone}
              onChange={handleChange}
              autoComplete='tel-national'
              required
            />
            {errors.phone && <p className='error-message'>{errors.phone}</p>}
          </div>
          <div className='form-group'>
            <label htmlFor='email'>Email</label>
            <input
              type='email'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              autoComplete='email'
              required
            />
            {errors.email && <p className='error-message'>{errors.email}</p>}
          </div>
          <div className='form-group'>
            <label htmlFor='message'>Message</label>
            <textarea
              id='message'
              name='message'
              value={formData.message}
              onChange={handleChange}
              autoComplete='off'
              required
            ></textarea>
          </div>
          <button className='btn primary-btn'>{buttonText}</button>
          {successMessage && (
            <p className='success-message'>{successMessage}</p>
          )}
          {errorMessage && <p className='error-message'>{errorMessage}</p>}
        </form>
      </div>
    </div>
  );
};

export default GeneralContact;
