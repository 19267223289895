import React, { useState } from 'react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { FaStar } from 'react-icons/fa';
import './testimonials.styles.css';

const testimonials = [
  {
    text: 'I was really impressed with the speed and accuracy of the PCR test. The results came back much faster than expected, which allowed me to take the necessary precautions immediately. The staff was friendly and professional, making the entire process smooth and stress-free.',
    author: 'A.S.',
  },
  {
    text: 'We switched to Grand Teton for our PCR testing, and it’s been awesome. The fast results and accuracy have really helped us out. Their team is always ready to help with any issues, making our lives a lot easier.',
    author: 'S.T.',
  },
  {
    text: "Our clinic has loved using Grand Teton. The results are fast and spot-on every time. Their support staff is super friendly and always ready to assist. It's been a great partnership for us.",
    author: 'K.L.',
  },
];

const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextTestimonial = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevTestimonial = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length
    );
  };

  return (
    <div className='testimonials-container'>
      <button onClick={prevTestimonial} className='arrow-button'>
        <FiChevronLeft className='quote-arrow-icon' />
      </button>
      <div key={currentIndex} className='testimonial'>
        <div className='stars'>
          {[...Array(5)].map((_, index) => (
            <FaStar key={index} size={16} className='star-icon' />
          ))}
        </div>
        <h2 className='testimonial-text'>{testimonials[currentIndex].text}</h2>
        <p className='testimonial-author'>
          {testimonials[currentIndex].author}
        </p>
      </div>
      <button onClick={nextTestimonial} className='arrow-button'>
        <FiChevronRight className='quote-arrow-icon' />
      </button>
    </div>
  );
};

export default Testimonials;
