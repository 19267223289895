import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import HeroContent from '../components/hero/HeroContent';
import TestGrid from '../components/Grids/TestGrid';
import Testimonials from '../components/testimonials/Testimonials';
// import HomeContact from '../components/contactForms/HomeContact';
import GeneralContact from '../components/contactForms/GeneralContact';

const Home = () => {
  const aboutRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('fade-up');
          observer.unobserve(entry.target); // Stop observing after the animation
        }
      },
      {
        threshold: 0.5, // Adjust as needed
      }
    );

    const currentRef = aboutRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div id='pageStart'>
      <div className='hero-section' id='pageStart'>
        <HeroContent />
      </div>
      <div className='about-section'>
        <div className='grid-section'>
          <div className='about-content hidden' ref={aboutRef}>
            <h1 className='section-header mb-3'>
              Unlocking the Power of Diagnostic Testing
            </h1>

            <h1 className='section-subheader mb-3'>Our Mission</h1>
            <p className='section-body text-primary'>
              Our specialized molecular PCR testing provides rapid and accurate
              diagnostics for a variety of infections, including urinary tract
              infections, respiratory infections, nail fungus, and wound
              infections.
            </p>
            <p className='section-body text-primary'>
              By detecting the specific pathogens causing the infection and
              identifying their antibiotic resistance profiles, we help
              healthcare professionals make informed decisions for optimal
              patient care.
            </p>
            <div className='btn-container mt-10'>
              <Link to='/about' className='btn secondary-btn'>
                Learn More
              </Link>
              <Link to='/contact' className='btn primary-btn ml-5'>
                Get Started
              </Link>
            </div>
          </div>
        </div>

        <div className='about-img'>
          <img
            src='https://res.cloudinary.com/dh8uixj4v/image/upload/v1719557816/shutterstock_417228691_1_jscljx.webp'
            alt='about-img'
            className='sqr-img'
          />
        </div>
      </div>

      <div className='test-menu-section mb-20'>
        <h1 className='section-header'>Test Menu</h1>
        <p className='section-subheader mb-14'>
          Choose From Our Selection of Tests
        </p>
        <TestGrid />
      </div>
      <div className='testimonial-section'>
        <div className='testimonial-header'>
          <h1 className='section-header'>Testimonials</h1>
          <p className='section-subheader'>
            See what our customers are saying
          </p>
        </div>
        <Testimonials />
      </div>
      <div className='contact-section mb-20'>
        <GeneralContact />
      </div>
    </div>
  );
};

export default Home;
