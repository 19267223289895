import React, { useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import testData from '../assets/TestData';
import '../pageStyles/testInfo.styles.css';

import InfoCard from '../components/Cards/InfoCard';

const TestInfo = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const { id } = useParams();
  const test = testData.find((test) => test.id === parseInt(id));

  const testCardRef = useRef(null);
  const testInfoContentRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-up');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (testCardRef.current) {
      observer.observe(testCardRef.current);
    }

    return () => {
      if (testCardRef.current) {
        observer.unobserve(testCardRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('slide-in');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.1 }
    );

    testInfoContentRefs.current.forEach((ref) => {
      if (ref) {
        observer.observe(ref);
      }
    });

    return () => {
      testInfoContentRefs.current.forEach((ref) => {
        if (ref) {
          observer.unobserve(ref);
        }
      });
    };
  }, []);

  if (!test) return <div>Test not found</div>;

  return (
    <div className='test-info-container'>
      <div className='test-info-header'>
        <h1 className='test-info-title'>
          Molecular PCR {test.title} with Antibiotic Resistance Markers
        </h1>
      </div>
      <div className='main-ti-wrapper'>
        <div className='test-info-section'>
          <div className='section-img'>
            <img src={test.thumbnailImg} alt='about-img' className='sqr-img' />
          </div>
          <div className='grid-section'>
            <div
              className='test-info-content'
              ref={(el) => testInfoContentRefs.current.push(el)}
            >
              <h1 className='ti-section-header mb-3'>
                Precision Diagnostics for Better Health with Grand Teton
              </h1>

              <h2 className='ti-section-subheader mb-3'>
                Comprehensive and Reliable Detection
              </h2>
              <p className='section-body'>{test.description}</p>
              <h2 className='ti-section-subheader mb-3'>
                Improving Patient Care with Targeted Solutions
              </h2>
              <p className='section-body'>{test.benefits}</p>
              <div className='btn-container mt-10'>
                <Link to='/contact' className='btn secondary-btn'>
                  Contact Us To Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className='test-info-section-b pt-5'>
          {isMobile ? (
            <>
              <div className='section-img'>
                <img
                  src={test.accentImg}
                  alt='about-img'
                  className='sqr-img'
                />
              </div>
              <div className='grid-section'>
                <div
                  className='test-info-content'
                  ref={(el) => testInfoContentRefs.current.push(el)}
                >
                  <h1 className='ti-section-header mb-3'>
                    Ensuring Accurate Diagnosis and Effective Treatment
                  </h1>
                  <h2 className='ti-section-subheader mb-3'>Recommended For</h2>
                  <p className='section-body'>{test.reason}</p>
                  <h2 className='ti-section-subheader mb-3'>Sample</h2>
                  <p className='section-body'>{test.sample}</p>
                  <div className='btn-container mt-10'>
                    <Link to='/contact' className='btn secondary-btn'>
                      Contact Us To Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='grid-section'>
                <div
                  className='test-info-content'
                  ref={(el) => testInfoContentRefs.current.push(el)}
                >
                  <h1 className='ti-section-header mb-3'>
                    Ensuring Accurate Diagnosis and Effective Treatment
                  </h1>
                  <h2 className='ti-section-subheader mb-3'>Recommended For</h2>
                  <p className='section-body'>{test.reason}</p>
                  <h2 className='ti-section-subheader mb-3'>Sample</h2>
                  <p className='section-body'>{test.sample}</p>
                  <div className='btn-container mt-10'>
                    <Link to='/contact' className='btn secondary-btn'>
                      Contact Us To Get Started
                    </Link>
                  </div>
                </div>
              </div>
              <div className='section-img'>
                <img
                  src={test.accentImg}
                  alt='about-img'
                  className='sqr-img'
                />
              </div>
            </>
          )}
        </div>
      </div>

      <div className='test-card-section' ref={testCardRef}>
        <div className='card-section-header'>
          <h1 className='mid-section-header'>
            Why Choose Grand Teton's Molecular PCR {test.title}?
          </h1>
        </div>

        <div className='card-info-section'>
          <InfoCard />
        </div>
      </div>

      <div className='pcr-adv-section'>
        <div className='grid-section'>
          <div
            className='test-info-content'
            ref={(el) => testInfoContentRefs.current.push(el)}
          >
            <h1 className='ti-section-header mb-3'>
              Advantages of Molecular PCR Testing
            </h1>
            <h2 className='pcr-subheader'>98% Accurate Results</h2>
            <p className='section-body'>
              Molecular testing plays a crucial role in diagnostic testing by
              offering a clear understanding of the type and percent of
              pathogens responsible for an infection through unique genetic
              markers and identification of patient-specific antibiotic
              resistance. As a result, allowing for targeted antibiotic therapy,
              improved patient outcomes, and reduced healthcare costs.
            </p>

            <h2 className='pcr-subheader'>Rapid Results</h2>
            <p className='section-body'>
              This method provides rapid and specific identification, essential
              for timely medical intervention and selecting optimal medication
              therapy. PCR is 60% more accurate than traditional culturing
              methods, delivering reliable results faster. Unlike traditional
              culture methods, which can take days or weeks, PCR delivers
              results within hours, improving treatment outcomes and reducing
              healthcare costs.
            </p>
            <p className='section-body'>
              Once Grand Teton receives a sample, comprehensive results are
              provided within 24-48 hours.
            </p>
            <div className='btn-container'>
              <Link to='/contact' className='btn secondary-btn'>
                Contact Us To Get Started
              </Link>
            </div>
          </div>
        </div>
        <div className='bottom-section-img'>
          <img
            src='https://res.cloudinary.com/dh8uixj4v/image/upload/v1719618817/shutterstock_2313654705_e5rl9k.webp'
            alt='about-img'
            className='pcr-img'
          />
        </div>
      </div>

      <div className='ti-contact-grid'>
        <div className='contact-grid-item left'>
          <h1 className='ti-contact-header'>Get in Touch</h1>
          <h2 className='ti-contact-subheader'>
            If you have any questions or would like to learn more about our
            services, please contact us.
          </h2>
          <Link to='/contact' className='btn primary-btn'>
            Contact Us
          </Link>
        </div>
        <div className='contact-grid-item right'>
          <div className='contact-column'>
            <h3>Phone</h3>
            <p>(214) 954-7483</p>
          </div>
          <div className='contact-column'>
            <h3>Email</h3>
            <p>contactus@gtdxlabs.com</p>
          </div>
          <div className='contact-column'>
            <h3>Address</h3>
            <p>
              1701 N. Collins Blvd. Suite 1000
              <br /> Richardson, TX 75080
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestInfo;
